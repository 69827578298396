import React from "react";
import Icon from "react-icons-kit";
import { spinner } from "react-icons-kit/fa/spinner";
import styled from "styled-components";

export default function Button2({
  disable = false,
  style,
  text,
  onClick,
  onDisableClick = () => { },
  backgroundColor = "var(--TCSecondaryColor)",
  color = "var(--TCPrimaryColor)",
  isPending = false
}) {
  return (
    <ButtonContainer
      color={disable ? "gray" : color}
      backgroundColor={backgroundColor}
      opacity={disable ? 0.4 : 1}
      cursor={disable ? "not-allowed" : "pointer"}
    >
      <button
        style={style}
        className={`button-30 ${disable ? "disabled" : ""}`}
        role="button"
        onClick={disable ? onDisableClick : onClick}
      >
        {isPending ? <Icon icon={spinner} size={24} className="spinLoading" /> : text}
      </button>
    </ButtonContainer>
  );
}
const ButtonContainer = styled.div`
  --color: ${(props) => props.color};
  --bgColor: ${(props) => props.backgroundColor};
  .button-30 {
    opacity: ${(props) => props.opacity};
    align-items: center;
    appearance: none;
    border-radius: 8px;
    border-width: 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-sizing: border-box;
    color: var(--color);
    cursor: ${(props) => props.cursor};
    display: inline-flex;
    font-family: "JetBrains Mono", monospace;
    height: 46px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 300%;
      height: 100%;
      background: white;
      transition: all 0.6s;
      z-index: -1;
      background: var(--bgColor);
      background: linear-gradient(
        145deg,
        var(--bgColor) 0%,
        rgba(255, 255, 255, 1) 45%,
        var(--bgColor) 50%,
        rgba(255, 255, 255, 1) 98%,
        var(--bgColor) 100%
      );
    }
    :hover::before {
      left: -200%;
    }
  }

  /* .button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
} */

  /* .button-30:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transform: translateY(-2px);
} */

  .button-30:not(.disabled):active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }
`;
